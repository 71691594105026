import "./App.css";
import svg from "./tytul.svg";
import stol from "./stol.svg";
import kanapa from "./kanapa.svg";
import polka from "./polka.svg";
import printer from "./printerscreencoffee.svg";

function App() {
  return (
    <div className="App">
      <div class="box">
        <div class="top-row">
          <div></div>
          <div className="titlebar">
            <img className="title" src={svg}></img>
          </div>
          <div></div>
        </div>
        <div class="middle-row">
          <div>
            <img className="graphic" src={stol}></img>
          </div>
          <div>
            <img className="graphic" src={kanapa}></img>
          </div>
          <div>
            <img className="graphic" src={polka}></img>
          </div>
        </div>
        <div className="bottom-row">
          <div>
            <div className="linktextbold">Samo Studio. A Studio for work.</div>
            <div className="linktextmail">samostudio@protonmail.com</div>
          </div>
          <div></div>
          <div>
            <img className="graphic" src={printer}></img>
          </div>
        </div>
      </div>

      {/* <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
